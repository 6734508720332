import React, { useState, useEffect } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { useConfig } from '../contexts/ConfigContext';
import UserPreferencesModal from './UserPreferencesModal';

// Update to include /verify-email in allowed paths
const ALLOWED_UNREGISTERED_PATHS = ['/login', '/signup', '/reset-password', '/verify-email'];

const LoadingSpinner = () => (
  <div className="fixed inset-0 bg-gray-100 flex items-center justify-center z-[9999]">
    <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-gray-900"></div>
  </div>
);

const PrivateRoute = ({ children }) => {
  const { 
    currentUser, 
    isAdmin, 
    isRegistrationComplete, 
    registrationChecked,
    saveUserPreferences 
  } = useAuth();
  const { config, isLoading: isConfigLoading } = useConfig();
  const location = useLocation();
  const navigate = useNavigate();
  const [preferencesLoading, setPreferencesLoading] = useState(false);
  const [showPreferences, setShowPreferences] = useState(false);

  // Effect to handle verification and redirect properly
  useEffect(() => {
    if (currentUser && registrationChecked && !ALLOWED_UNREGISTERED_PATHS.includes(location.pathname)) {
      if (!currentUser.emailVerified) {
        navigate('/verify-email', { state: { from: location }, replace: true });
      } else if (isRegistrationComplete) {
        setShowPreferences(false);
      } else {
        setShowPreferences(true);
      }
    }
  }, [currentUser, registrationChecked, isRegistrationComplete, location, navigate]);

  const handlePreferencesSubmit = async (preferences) => {
    try {
      setPreferencesLoading(true);
      await saveUserPreferences(currentUser.email, preferences);
    } catch (error) {
      console.error('Error saving preferences:', error);
    } finally {
      setPreferencesLoading(false);
    }
  };

  // Early return for unregistered paths
  if (ALLOWED_UNREGISTERED_PATHS.includes(location.pathname)) {
    return children;
  }

  // Show loading for registration or config checks
  if (!registrationChecked || isConfigLoading) {
    return <LoadingSpinner />;
  }

  // Authentication check
  if (!currentUser) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  // Email verification check - we use the useEffect above to handle this more securely
  if (!currentUser.emailVerified) {
    return <Navigate to="/verify-email" state={{ from: location }} replace />;
  }

  // Registration check (only show preferences modal if email is verified AND registration is incomplete)
  if (showPreferences && currentUser.emailVerified && !isRegistrationComplete) {
    return (
      <div className="fixed inset-0 bg-gray-100 flex items-center justify-center z-[9999]">
        <UserPreferencesModal
          isOpen={true}
          onClose={() => null}
          onSubmit={handlePreferencesSubmit}
          isLoading={preferencesLoading}
        />
      </div>
    );
  }

  return React.cloneElement(children, { isAdmin });
};

export default PrivateRoute;
import React, { useEffect, useRef, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation, useNavigate } from 'react-router-dom';
import Layout from './components/Layout';
import Home from './pages/Home';
import DynamicQuery from './pages/DynamicQuery';
import StaticDashboard from './pages/StaticDashboard';
import Login from './pages/SignIn';
import Signup from './pages/Signup';
import PasswordReset from './pages/PasswordReset';
import Plan from './pages/Plan';
import Optimization from './pages/Optimization';
import Ask from './pages/Ask';
import Chat from './pages/Chat';
import Organize from './pages/Organize';
import News from './pages/News';
import Review from './pages/Review';
import AdminDashboard from './pages/AdminDashboard';
import DataPrep from './pages/DataPrep';
import UserPreferencesModal from './components/UserPreferencesModal';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import { ThemeProvider } from './contexts/ThemeContext';
import { DashboardProvider } from './contexts/DashboardContext';
import { AskProvider } from './contexts/AskContext';
import { ConfigProvider, useConfig } from './contexts/ConfigContext';
import BigBroProvider from './components/BigBroProvider';
import EmailVerification from './pages/EmailVerification';
import './assets/bubblingLoader.css';
import PrivateRoute from './components/PrivateRoute';


const ALLOWED_UNREGISTERED_PATHS = ['/login', '/signup', '/reset-password', '/verify-email'];


function LoadingSpinner() {
  return (
    <div className="fixed inset-0 bg-gray-100 flex items-center justify-center z-50">
      <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-gray-900"></div>
    </div>
  );
}

function AppContent() {
  const mainContentRef = useRef(null);
  const { currentUser, isAdmin, checkAdminStatus, isRegistrationComplete } = useAuth();
  const { config, isLoading: isConfigLoading } = useConfig();
  const location = useLocation();
  const [sidebarHovered, setSidebarHovered] = useState(false);



useEffect(() => {
  let intervalId;
  let isInitialCheck = true;
  
  if (currentUser) {
    console.log('AppContent: Current user detected');
    const refreshTokenAndCheckAdmin = async () => {
      try {
        if (!isInitialCheck) {
          const adminStatus = await checkAdminStatus(currentUser);
          console.log('AppContent: Admin status after check:', adminStatus);
        }
      } catch (error) {
        console.error('Error in token refresh:', error);
      }
    };

    // Set initial check
    if (isInitialCheck) {
      isInitialCheck = false;
    }

    // Set up interval for future checks (15 minutes)
    intervalId = setInterval(refreshTokenAndCheckAdmin, 15 * 60 * 1000);
  } else {
    console.log('AppContent: No current user');
  }

  return () => {
    if (intervalId) {
      clearInterval(intervalId);
    }
  };
}, [currentUser, checkAdminStatus]);

useEffect(() => {
  if (config?.core?.version) {
    console.log('Setting initial app version:', config.core.version);
    localStorage.setItem('appVersion', config.core.version);
  } else {
    console.log('No version found in config:', config);
  }
}, [config]);

  // Combined loading state check
  if (isConfigLoading) {
    return <LoadingSpinner />;
  }

  const shouldShowLayout = isRegistrationComplete || 
    ALLOWED_UNREGISTERED_PATHS.includes(location.pathname) ||
    !currentUser;

    return (
      <Layout mainContentRef={mainContentRef} showSidebar={shouldShowLayout}>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/reset-password" element={<PasswordReset />} />
          <Route path="/verify-email" element={<EmailVerification />} />
          <Route path="/" element={
            <PrivateRoute>
              <Ask mainContentRef={mainContentRef} />
            </PrivateRoute>
          } />
          <Route path="/chat" element={
            <PrivateRoute>
              <Chat mainContentRef={mainContentRef} />
            </PrivateRoute>
          } />
          <Route path="/dashboard" element={
            <PrivateRoute>
              <Home mainContentRef={mainContentRef} />
            </PrivateRoute>
          } />
          <Route path="/news" element={
            <PrivateRoute>
              <News />
            </PrivateRoute>
          } />
          <Route path="/dynamic" element={
            <PrivateRoute>
              <DynamicQuery mainContentRef={mainContentRef} />
            </PrivateRoute>
          } />
          <Route path="/static-dashboard" element={
            <PrivateRoute>
              <StaticDashboard />
            </PrivateRoute>
          } />
          <Route path="/plan" element={
            <PrivateRoute>
              <Plan />
            </PrivateRoute>
          } />
          <Route path="/operate" element={
            <PrivateRoute>
              <Optimization />
            </PrivateRoute>
          } />
          <Route path="/boost" element={
            <PrivateRoute>
              <DataPrep />
            </PrivateRoute>
          } />
          <Route path="/organize" element={
            <PrivateRoute>
              <Organize />
            </PrivateRoute>
          } />
          <Route path="/review" element={
            <PrivateRoute>
              <Review mainContentRef={mainContentRef} />
            </PrivateRoute>
          } />
          <Route path="/admin/*" element={
            <PrivateRoute>
              <AdminDashboard />
            </PrivateRoute>
          } />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Layout>
    );
  }

function App() {
  useEffect(() => {
    const currentBuildTime = localStorage.getItem('buildTime');
    const newBuildTime = process.env.REACT_APP_BUILD_TIME;

    if (currentBuildTime !== newBuildTime) {
      localStorage.setItem('buildTime', newBuildTime);
      if (currentBuildTime) {
        window.location.reload(true);
      }
    }
  }, []);

  // Adding a console.log to confirm initialization order
  console.log('App component starting render - BigBroProvider should activate');

  return (
    <ThemeProvider>
      <AuthProvider>
        <ConfigProvider>
          <DashboardProvider>
            <AskProvider>
              <BigBroProvider>
                <Router>
                  <AppContent />
                </Router>
              </BigBroProvider>
            </AskProvider>
          </DashboardProvider>
        </ConfigProvider>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
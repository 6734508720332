import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { useConfig } from '../contexts/ConfigContext';
import { useNavigate, Link, useLocation } from 'react-router-dom';

function SignIn() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { login, checkAdminStatus } = useAuth();
  const { refresh: refreshConfig } = useConfig();
  const navigate = useNavigate();
  const location = useLocation();

  // Clear error when inputs change
  useEffect(() => {
    if (error) setError('');
  }, [email, password]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError('');
  
    try {
      const normalizedEmail = email.toLowerCase();
      console.log('Attempting to sign in with email:', normalizedEmail);
      const userCredential = await login(normalizedEmail, password);
      console.log('Login successful');
  
      // Check if email is verified
      if (!userCredential.user.emailVerified) {
        console.log('Email not verified, redirecting to verification page');
        navigate('/verify-email');
        setIsLoading(false);
        return;
      }
  
      // Then refresh config (this ensures we have proper permissions)
      try {
        await refreshConfig();
      } catch (configError) {
        console.warn('Config refresh error:', configError);
        // Continue with sign in even if config refresh fails
      }
  
      // Check admin status
      try {
        const adminStatus = await checkAdminStatus(userCredential.user);
        console.log('Admin status verified:', adminStatus);
      } catch (adminError) {
        console.warn('Admin status check error:', adminError);
        // Continue even if admin check fails
      }
  
      // Navigate to intended destination or home
      const destination = location.state?.from?.pathname || '/';
      navigate(destination, { replace: true });
    } catch (error) {
      console.error('Sign in error:', error);
      
      // User-friendly error messages
      if (error.code === 'auth/wrong-password' || error.code === 'auth/user-not-found') {
        setError('Invalid email or password');
      } else if (error.code === 'auth/too-many-requests') {
        setError('Too many attempts. Please try again later');
      } else {
        setError('Failed to sign in. Please try again');
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-background">
      <div className="w-full max-w-md px-4 py-8">
        <h1 className="text-title text-text mb-6 text-center">Sign In</h1>
        <div className="bg-white shadow-card rounded-lg p-6">
          {error && (
            <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4" role="alert">
              <p>{error}</p>
            </div>
          )}
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label htmlFor="email" className="block text-text-light text-sm font-medium mb-2">
                Email Address
              </label>
              <input
                type="email"
                id="email"
                className="w-full px-3 py-2 border border-text-lighter rounded-md focus:outline-none focus:ring-1 focus:ring-primary"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                disabled={isLoading}
              />
            </div>
            <div className="mb-6">
              <label htmlFor="password" className="block text-text-light text-sm font-medium mb-2">
                Password
              </label>
              <input
                type="password"
                id="password"
                className="w-full px-3 py-2 border border-text-lighter rounded-md focus:outline-none focus:ring-1 focus:ring-primary"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                disabled={isLoading}
              />
            </div>
            <button
              type="submit"
              className={`w-full bg-secondary text-text py-2 px-4 rounded-md hover:bg-opacity-80 focus:outline-none focus:ring-2 focus:ring-secondary focus:ring-opacity-50 transition duration-300 ${
                isLoading ? 'opacity-50 cursor-not-allowed' : ''
              }`}
              disabled={isLoading}
            >
              {isLoading ? (
                <span className="flex items-center justify-center">
                  <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                  Signing in...
                </span>
              ) : (
                'Sign In'
              )}
            </button>
          </form>
          <div className="mt-4 flex flex-col items-center space-y-4 text-sm">
            <Link 
              to="/reset-password" 
              className="text-primary hover:underline"
              tabIndex={isLoading ? -1 : 0}
            >
              Forgot Password?
            </Link>
            <div className="w-full border-t border-gray-200"></div>
            <p className="text-text-light">
              Don't have an account?{' '}
              <Link 
                to="/signup" 
                className="text-primary hover:underline font-medium"
                tabIndex={isLoading ? -1 : 0}
              >
                Sign up
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignIn;
// src/components/DBIConfigManagement.js
import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { Loader2 } from 'lucide-react';
import Card from './Card';
import { useAuth } from '../contexts/AuthContext';

const DBIConfigManagement = () => {
  const [configData, setConfigData] = useState({
    Model: '',
    Intent_Detection_Model: '',
    Multi_Table_Review_Model: '',
    Explanation_Model: '',
    Preference_Learning_Model: '',
    Big_Bro_Model: '',
    Personalized: false,
    enableMatching: false,
    enableIntentDetection: false,
    enableMultiTableReview: false,
    EnableBigBro: false,
    initialQueryThreshold: 5,
    retriggerThreshold: 3,
    historyQueryCount: 20,
    learningScheduleMinutes: 500,
    MaxRows: 500000,
    Support: []
  });
  const [availableModels, setAvailableModels] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [saveStatus, setSaveStatus] = useState({ type: '', message: '' });
  const { currentUser } = useAuth();

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        setIsLoading(true);
        
        // Fetch Models document
        const modelsDoc = await getDoc(doc(db, 'DBI Configuration', 'Models'));
        if (modelsDoc.exists()) {
          const modelsData = modelsDoc.data();
          // Transform the models data into a format for the dropdown
          const modelsList = Object.entries(modelsData).map(([name, details]) => ({
            name,
            ...details
          }));
          setAvailableModels(modelsList);
        }

        // Fetch Config document
        const configDoc = await getDoc(doc(db, 'DBI Configuration', 'Config'));
        if (configDoc.exists()) {
          const configDataFromDb = configDoc.data();
          
          setConfigData({
            Model: configDataFromDb.Model || '',
            Intent_Detection_Model: configDataFromDb.Intent_Detection_Model || '',
            Multi_Table_Review_Model: configDataFromDb.Multi_Table_Review_Model || '',
            Explanation_Model: configDataFromDb.Explanation_Model || '',
            Preference_Learning_Model: configDataFromDb.Preference_Learning_Model || '',
            Big_Bro_Model: configDataFromDb.Big_Bro_Model || '',
            Personalized: Boolean(configDataFromDb.Personalized),
            enableMatching: Boolean(configDataFromDb.enableMatching),
            enableIntentDetection: Boolean(configDataFromDb.enableIntentDetection),
            enableMultiTableReview: Boolean(configDataFromDb.enableMultiTableReview),
            EnableBigBro: Boolean(configDataFromDb.EnableBigBro),
            initialQueryThreshold: configDataFromDb.initialQueryThreshold || 5,
            retriggerThreshold: configDataFromDb.retriggerThreshold || 3,
            historyQueryCount: configDataFromDb.historyQueryCount || 20,
            learningScheduleMinutes: configDataFromDb.learningScheduleMinutes || 500,
            MaxRows: configDataFromDb.MaxRows || 500000,
            Support: configDataFromDb.Support || []
          });
        }

      } catch (err) {
        console.error('Error fetching configuration:', err);
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchConfig();
  }, []);

  const handleConfigChange = async (field, value) => {
    try {
      setSaveStatus({ type: 'loading', message: `Updating ${field}...` });
      
      await updateDoc(doc(db, 'DBI Configuration', 'Config'), {
        [field]: value
      });

      setConfigData(prev => ({
        ...prev,
        [field]: value
      }));

      setSaveStatus({ type: 'success', message: `${field} updated successfully!` });
    } catch (err) {
      console.error(`Error updating ${field}:`, err);
      setSaveStatus({ type: 'error', message: `Failed to update ${field}` });
    } finally {
      setTimeout(() => setSaveStatus({ type: '', message: '' }), 3000);
    }
  };

  const handleSupportEmailsChange = async (emails) => {
    try {
      setSaveStatus({ type: 'loading', message: 'Updating support emails...' });
      
      await updateDoc(doc(db, 'DBI Configuration', 'Config'), {
        Support: emails
      });

      setConfigData(prev => ({
        ...prev,
        Support: emails
      }));

      setSaveStatus({ type: 'success', message: 'Support emails updated successfully!' });
    } catch (err) {
      console.error('Error updating support emails:', err);
      setSaveStatus({ type: 'error', message: 'Failed to update support emails' });
    } finally {
      setTimeout(() => setSaveStatus({ type: '', message: '' }), 3000);
    }
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center p-8">
        <Loader2 className="h-8 w-8 animate-spin text-gray-500" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="p-4 text-red-500">
        Error loading configuration: {error}
      </div>
    );
  }

  return (
    <div className="w-full">
      <div className="flex justify-between items-center mb-4">
        {saveStatus.message && (
          <div className={`px-4 py-2 rounded ${
            saveStatus.type === 'success' ? 'bg-green-100 text-green-700' :
            saveStatus.type === 'error' ? 'bg-red-100 text-red-700' :
            'bg-blue-100 text-blue-700'
          }`}>
            {saveStatus.message}
          </div>
        )}
      </div>
      
      <Card className="h-full">
        <div className="p-4">
          <div className="mb-6">
            <h3 className="text-lg font-medium mb-4">Configuration</h3>
            <div className="space-y-6">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Query Model
                </label>
                <select
                  value={configData.Model}
                  onChange={(e) => handleConfigChange('Model', e.target.value)}
                  className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                >
                  <option value="">Select a model</option>
                  {availableModels.map((model) => (
                    <option key={model.name} value={model.name}>
                      {model.name} ({model.Platform} - {model.Model})
                    </option>
                  ))}
                </select>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Multi-Table Review Model
                </label>
                <select
                  value={configData.Multi_Table_Review_Model}
                  onChange={(e) => handleConfigChange('Multi_Table_Review_Model', e.target.value)}
                  className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                >
                  <option value="">Select a model</option>
                  {availableModels.map((model) => (
                    <option key={model.name} value={model.name}>
                      {model.name} ({model.Platform} - {model.Model})
                    </option>
                  ))}
                </select>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Intent Detection Model
                </label>
                <select
                  value={configData.Intent_Detection_Model}
                  onChange={(e) => handleConfigChange('Intent_Detection_Model', e.target.value)}
                  className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                >
                  <option value="">Select a model</option>
                  {availableModels.map((model) => (
                    <option key={model.name} value={model.name}>
                      {model.name} ({model.Platform} - {model.Model})
                    </option>
                  ))}
                </select>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Explanation Model
                </label>
                <select
                  value={configData.Explanation_Model}
                  onChange={(e) => handleConfigChange('Explanation_Model', e.target.value)}
                  className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                >
                  <option value="">Select a model</option>
                  {availableModels.map((model) => (
                    <option key={model.name} value={model.name}>
                      {model.name} ({model.Platform} - {model.Model})
                    </option>
                  ))}
                </select>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Preference Learning Model
                </label>
                <select
                  value={configData.Preference_Learning_Model}
                  onChange={(e) => handleConfigChange('Preference_Learning_Model', e.target.value)}
                  className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                >
                  <option value="">Select a model</option>
                  {availableModels.map((model) => (
                    <option key={model.name} value={model.name}>
                      {model.name} ({model.Platform} - {model.Model})
                    </option>
                  ))}
                </select>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Big Bro Analysis Model (must be OpenAI)
                </label>
                <select
                  value={configData.Big_Bro_Model}
                  onChange={(e) => handleConfigChange('Big_Bro_Model', e.target.value)}
                  className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                >
                  <option value="">Select a model</option>
                  {availableModels.map((model) => (
                    <option key={model.name} value={model.name}>
                      {model.name} ({model.Platform} - {model.Model})
                    </option>
                  ))}
                </select>
                <p className="text-xs text-gray-500 mt-1">
                  Model used for analyzing queries and providing user guidance
                </p>
              </div>

              <div className="flex items-center space-x-2">
                <input
                  type="checkbox"
                  checked={configData.Personalized}
                  onChange={(e) => handleConfigChange('Personalized', e.target.checked)}
                  className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                />
                <label className="text-sm font-medium text-gray-700">
                  Enable Personalization
                </label>
              </div>

              <div className="flex items-center space-x-2">
                <input
                  type="checkbox"
                  checked={configData.enableIntentDetection}
                  onChange={(e) => handleConfigChange('enableIntentDetection', e.target.checked)}
                  className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                />
                <label className="text-sm font-medium text-gray-700">
                  Enable Intent Detection
                </label>
              </div>

              <div className="flex items-center space-x-2">
                <input
                  type="checkbox"
                  checked={configData.enableMultiTableReview}
                  onChange={(e) => handleConfigChange('enableMultiTableReview', e.target.checked)}
                  className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                />
                <label className="text-sm font-medium text-gray-700">
                  Enable Multi-Table Review
                </label>
              </div>

              <div className="flex items-center space-x-2">
                <input
                  type="checkbox"
                  checked={configData.EnableBigBro}
                  onChange={(e) => handleConfigChange('EnableBigBro', e.target.checked)}
                  className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                />
                <label className="text-sm font-medium text-gray-700">
                  Enable Big Bro Analysis
                </label>
              </div>

              <div className="flex items-center space-x-2">
                <input
                  type="checkbox"
                  checked={configData.enableMatching}
                  onChange={(e) => handleConfigChange('enableMatching', e.target.checked)}
                  className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                />
                <label className="text-sm font-medium text-gray-700">
                  Enable Matching
                </label>
              </div>

              <div className="border-t pt-4">
                <h4 className="text-md font-medium mb-3">Preference Learning Settings</h4>
                
                <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mb-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                      Initial Query Threshold
                    </label>
                    <input
                      type="number"
                      min="1"
                      max="20"
                      value={configData.initialQueryThreshold}
                      onChange={(e) => handleConfigChange('initialQueryThreshold', parseInt(e.target.value, 10))}
                      className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                    <p className="text-xs text-gray-500 mt-1">
                      Number of follow-ups before first learning run
                    </p>
                  </div>
                  
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                      Retrigger Threshold
                    </label>
                    <input
                      type="number"
                      min="1"
                      max="10"
                      value={configData.retriggerThreshold}
                      onChange={(e) => handleConfigChange('retriggerThreshold', parseInt(e.target.value, 10))}
                      className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                    <p className="text-xs text-gray-500 mt-1">
                      New follow-ups needed to re-analyze preferences
                    </p>
                  </div>
                  
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                      History Query Count
                    </label>
                    <input
                      type="number"
                      min="5"
                      max="50"
                      value={configData.historyQueryCount}
                      onChange={(e) => handleConfigChange('historyQueryCount', parseInt(e.target.value, 10))}
                      className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                    <p className="text-xs text-gray-500 mt-1">
                      Number of previous queries to analyze
                    </p>
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                      Learning Schedule Interval
                    </label>
                    <input
                      type="number"
                      min="30"
                      max="1440"
                      value={configData.learningScheduleMinutes}
                      onChange={(e) => handleConfigChange('learningScheduleMinutes', parseInt(e.target.value, 10))}
                      className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                    <p className="text-xs text-gray-500 mt-1">
                      How often the scheduled job runs (in minutes)
                    </p>
                  </div>
                </div>
              </div>

              <div className="border-t pt-4">
                <h4 className="text-md font-medium mb-3">Data Download Settings</h4>
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Maximum Rows for Download
                  </label>
                  <input
                    type="number"
                    min="1000"
                    max="1000000"
                    value={configData.MaxRows}
                    onChange={(e) => handleConfigChange('MaxRows', parseInt(e.target.value, 10))}
                    className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                  <p className="text-xs text-gray-500 mt-1">
                    Maximum number of rows that can be downloaded (1,000 - 1,000,000)
                  </p>
                </div>
              </div>

              <div className="border-t pt-4">
                <h4 className="text-md font-medium mb-3">Support Settings</h4>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Support Emails
                </label>
                <div className="space-y-2">
                  {configData.Support.map((email, index) => (
                    <div key={index} className="flex items-center space-x-2">
                      <input
                        type="email"
                        value={email}
                        onChange={(e) => {
                          const newEmails = [...configData.Support];
                          newEmails[index] = e.target.value;
                          handleSupportEmailsChange(newEmails);
                        }}
                        className="flex-1 px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                      />
                      <button
                        onClick={() => {
                          const newEmails = configData.Support.filter((_, i) => i !== index);
                          handleSupportEmailsChange(newEmails);
                        }}
                        className="text-red-500 hover:text-red-700"
                      >
                        Remove
                      </button>
                    </div>
                  ))}
                  <button
                    onClick={() => {
                      const newEmails = [...configData.Support, ''];
                      handleSupportEmailsChange(newEmails);
                    }}
                    className="px-3 py-2 bg-gray-100 text-gray-700 rounded-md hover:bg-gray-200"
                  >
                    + Add Email
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default DBIConfigManagement;
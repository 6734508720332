import React, { useState, useRef, useEffect } from 'react';
import { useTextareaHeight, LINE_HEIGHT, MAX_LINES } from '../../hooks/useTextareaHeight';
import useRecentSearches from '../../hooks/useRecentSearches';
import { SendHorizontal, Pause, Play, X, ArrowLeft, SlidersHorizontal } from 'lucide-react';
import RecentSearches from './RecentSearches';
import QuickFilter from './QuickFilter';
import MentionList from './MentionList';
import AutoCompleteSearch from './AutoCompleteSearch';
import ProposedQuestions from './ProposedQuestions';


const QueryInput = ({ 
  onSubmit, 
  isLoading, 
  isFollowUp = false, 
  isFollowUpMode = false, 
  selectedText = '', 
  selectedColumns = [], 
  isCardTitle = false, 
  fetchRecentSearches,
  initialQuery = '',
  queryResult = null,
  hideProposedQuestions = false
}) => {
  const [inputQuery, setInputQuery] = useState(initialQuery);
  const [isFocused, setIsFocused] = useState(false);
  const [showRecentSearches, setShowRecentSearches] = useState(false);
  const [highlightedIndex, setHighlightedIndex] = useState(-1);
  const [historyIndex, setHistoryIndex] = useState(-1);
  const [selectedHistoricalQuery, setSelectedHistoricalQuery] = useState(null);
  const textareaRef = useRef(null);
  const chipRef = useRef(null);
  const columnsChipRef = useRef(null);
  const timeoutRef = useRef(null);
  const recentSearchesRef = useRef(null);
  const isClearingRef = useRef(false);
  const [showQuickFilter, setShowQuickFilter] = useState(false);
  const [showMentionList, setShowMentionList] = useState(false);
  const [selectedTable, setSelectedTable] = useState(null);
  const [selectedColumn, setSelectedColumn] = useState(null);
  const [cursorPosition, setCursorPosition] = useState(0);
  const { height, adjustHeight, resetHeight, MIN_TEXTAREA_HEIGHT } = useTextareaHeight(textareaRef, inputQuery);
  const [currentPlaceholder, setCurrentPlaceholder] = useState('Use @ to focus the query');
  const [showAutoComplete, setShowAutoComplete] = useState(true);
  const [suggestions, setSuggestions] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [currentContext, setCurrentContext] = useState({});
  const [usesTableMention, setUsesTableMention] = useState(false);



  useEffect(() => {
    if (isFollowUpMode && textareaRef.current) {
      setTimeout(() => {
        textareaRef.current.focus();
      }, 100);
    }
  }, [isFollowUpMode]);

  useEffect(() => {
    if (selectedTable) {
      setCurrentPlaceholder('@ again for specific field');
    } else {
      setCurrentPlaceholder('Use @ to focus the query');
    }
  }, [selectedTable]);


  // Handler for proposed questions
  const handleSelectProposedQuestion = (question) => {
    setInputQuery(question);
    setShowRecentSearches(false);
    setShowAutoComplete(false);
    setSuggestions([]);
    adjustHeight();
    
    // Focus the textarea after setting the question
    requestAnimationFrame(() => {
      if (textareaRef.current) {
        textareaRef.current.focus();
      }
    });
  };

  const getQueryContext = () => {
    return {
      table: selectedTable ? {
        name: selectedTable.displayName,
        id: selectedTable.id
      } : null,
      column: selectedColumn ? {
        name: selectedColumn.name,
        type: selectedColumn.type
      } : null,
      isQuickFilter: showQuickFilter,
      usesTableMention: usesTableMention
    };
  };
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Always clear all UI states immediately, regardless of query type
    setShowRecentSearches(false);
    setShowAutoComplete(false);
    setSuggestions([]);
    setShowMentionList(false);
    
    if (inputQuery.trim()) {
      // Check if the query contains @ symbol as a table mention
      const containsAtSymbol = inputQuery.includes('@');
      if (containsAtSymbol) {
        setUsesTableMention(true);
      }
      
      // Make sure valueContext is always an array
      const context = {
        ...getQueryContext(),
        ...currentContext,
        valueContext: Array.isArray(currentContext.valueContext) ? currentContext.valueContext : 
                     (currentContext.valueContext ? [currentContext.valueContext] : []),
        isFromValueSearchList: currentContext.isFromValueSearchList || false,
        usesTableMention: usesTableMention || containsAtSymbol
      };
      
      // Check if input matches the tango_ID format
      const tangoRegex = /^tango_([a-zA-Z0-9]+)$/;
      const match = inputQuery.trim().match(tangoRegex);

      if (match) {
        // If it's a tango_ID, add it to the context
        const documentId = match[1];
        context.isTangoId = true;
        context.tangoDocumentId = documentId;
        console.log('Detected tango_ID format:', documentId);
      }
  
      console.log('Submitting with context:', context);  // Debug log
  
      try {
        if (selectedHistoricalQuery) {
          await onSubmit(inputQuery, false, '', context);
          setSelectedHistoricalQuery(null);
        } else {
          if (isFollowUpMode) {
            await onSubmit(inputQuery, { 
              text: selectedText, 
              columns: selectedColumns,
              ...context,
              valueContext: context.valueContext,  // Explicitly pass valueContext
              usesTableMention: context.usesTableMention // Pass the table mention flag
            });
          } else {
            await onSubmit(inputQuery, isFollowUpMode, selectedText, context);
          }
        }
      } catch (error) {
        console.error('Error submitting query:', error);
      } finally {
        // Clear context after submission, regardless of success/failure
        setCurrentContext({});
        // Ensure UI states are cleared again after async operation
        setShowAutoComplete(false);
        setSuggestions([]);
        setShowMentionList(false);
      }
    }
  };

  const {
    filteredRecentSearches,
    handleSearchFiltering,
    refreshRecentSearches,
    clearRecentSearches,
    recentSearches
  } = useRecentSearches(fetchRecentSearches, 10);

// Modified handleAutoComplete function
const handleAutoComplete = ({ suggestion, wordStart, wordEnd, replacementText }) => {
  const textBeforeWord = inputQuery.slice(0, wordStart);
  const textAfterWord = inputQuery.slice(wordEnd);
  
  // Add double quotes around the replacement text
  const formattedReplacementText = `"${replacementText}"`;
  
  const updatedInput = `${textBeforeWord}${formattedReplacementText}${textAfterWord}`;
  setInputQuery(updatedInput);
  setShowAutoComplete(false);
  setSuggestions([]);
  
  // Store the context about tables containing this value
  const newValueContext = suggestion.valueContext;

  // Update cursor position after the inserted text (including backquotes)
  const newPosition = wordStart + formattedReplacementText.length;
  requestAnimationFrame(() => {
    if (textareaRef.current) {
      textareaRef.current.focus();
      textareaRef.current.setSelectionRange(newPosition, newPosition);
    }
    setCursorPosition(newPosition);
  });

  // Create updated context with merged valueContext and any existing context
  const updatedContext = {
    ...getQueryContext(),
    ...currentContext,
    valueContext: [
      ...(currentContext.valueContext || []),
      ...(newValueContext || [])
    ],
    isFromValueSearchList: true
  };

  // Update the query context
  setCurrentContext(updatedContext);
};

const SelectedColumnsChip = ({ columns }) => (
  <div className="absolute top-2 left-16 flex flex-wrap gap-1 z-10">
    {columns.map(column => (
      <span key={column} className="bg-secondary text-white text-xs px-2 py-1 rounded-full">
        {column}
      </span>
    ))}
  </div>
);

  const handleHistoryBack = async () => {
    if (recentSearches.length === 0) {
      await refreshRecentSearches();
    }
    
    if (recentSearches.length > 0) {
      if (historyIndex === -1) {
        setHistoryIndex(0);
        setInputQuery(recentSearches[0].userQuery);
        setSelectedHistoricalQuery(recentSearches[0]);
      } else if (historyIndex < recentSearches.length - 1) {
        const newIndex = historyIndex + 1;
        setHistoryIndex(newIndex);
        setInputQuery(recentSearches[newIndex].userQuery);
        setSelectedHistoricalQuery(recentSearches[newIndex]);
      }
    }
    adjustHeight();
  };

  useEffect(() => {
    if (inputQuery !== (recentSearches[historyIndex]?.userQuery || '')) {
      setHistoryIndex(-1);
    }
  }, [inputQuery, recentSearches, historyIndex]);

  const checkForMentionTrigger = (value, cursorPos) => {
    const lastChar = value.charAt(cursorPos - 1);
    const isAtStart = cursorPos === 1;
    const prevChar = isAtStart ? ' ' : value.charAt(cursorPos - 2);
    
    if (lastChar === '@' && (!prevChar || /\s/.test(prevChar))) {
      setShowMentionList(true);
      setShowRecentSearches(false);
      setShowAutoComplete(false);
    }
  };
  
  const handleTextareaChange = async (e) => {
    const newValue = e.target.value;
    const newCursorPosition = e.target.selectionStart;
    
    setInputQuery(newValue);
    setCursorPosition(newCursorPosition);
    
    // If input is empty, reset table selection
    if (!newValue.trim()) {
      setSelectedTable(null);
      setSelectedColumn(null);
      setShowMentionList(false);
    }
    
    // Check for @ trigger
    checkForMentionTrigger(newValue, newCursorPosition);
    
    if (!showMentionList) {
      handleSearchFiltering(newValue);
    } else {
      setShowRecentSearches(false);
    }
    
    adjustHeight();
  };

  const handleKeyDown = (e) => {
    if (showMentionList) {
      // Let MentionList handle its own keyboard events
      return;
    }
  
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      
      // Only handle autocomplete if it's visible AND has suggestions
      if (showAutoComplete && suggestions.length > 0 && selectedIndex >= 0) {
        handleAutoComplete(suggestions[selectedIndex]);
        return;
      }
      
      // Only handle recent queries if they're visible and one is highlighted
      if (showRecentSearches && highlightedIndex !== -1 && filteredRecentSearches.length > 0) {
        handleSelectRecentQuery(filteredRecentSearches[highlightedIndex]);
      } else {
        // If no other conditions are met, submit the query
        handleSubmit(e);
      }
    } else if (e.key === 'ArrowDown') {
      if (showRecentSearches && filteredRecentSearches.length > 0) {
        e.preventDefault();
        setHighlightedIndex((prevIndex) =>
          prevIndex < filteredRecentSearches.length - 1 ? prevIndex + 1 : prevIndex
        );
      }
    } else if (e.key === 'ArrowUp') {
      if (showRecentSearches && filteredRecentSearches.length > 0) {
        e.preventDefault();
        setHighlightedIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : -1));
      }
    } else if (e.key === 'Escape') {
      setShowRecentSearches(false);
      setShowMentionList(false);
      setHighlightedIndex(-1);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (recentSearchesRef.current && !recentSearchesRef.current.contains(event.target) &&
          textareaRef.current && !textareaRef.current.contains(event.target)) {
        setShowRecentSearches(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (initialQuery) {
      setInputQuery(initialQuery);
      adjustHeight();
    }
  }, [initialQuery]);

// Optimized clearInput function
const clearInput = () => {
  // Clear text and basic states
  setInputQuery('');
  setSelectedHistoricalQuery(null);
  setHighlightedIndex(-1);
  
  // Clear mention-related states
  setSelectedTable(null);
  setSelectedColumn(null);
  setShowMentionList(false);
  setCursorPosition(0);
  setUsesTableMention(false);
  
  // Clear search-related states
  setShowAutoComplete(false);
  setSuggestions([]);
  setShowRecentSearches(true);
  setCurrentContext({});
  
  // Reset UI states
  requestAnimationFrame(() => {
    resetHeight();
    textareaRef.current?.focus();
  });

  clearRecentSearches();
};

  const truncateSelectedText = (text) => {
    if (typeof text === 'boolean') {
      return 'Follow-up';
    }
    return text && text.length > 20 ? text.substring(0, 17) + '...' : text;
  };

  const handleSelectRecentQuery = (recentQuery) => {
    setInputQuery(recentQuery.userQuery);
    setShowRecentSearches(false);
    setShowAutoComplete(false);
    setSuggestions([]);
    setHighlightedIndex(-1);
    onSubmit(recentQuery);
  };

  const handleFocus = async () => {
    setIsFocused(true);
    if (!isFollowUpMode) {
      setShowRecentSearches(true);
      refreshRecentSearches();
    }
  };

  // NEW CODE (to update handleQuickFilterSubmit function)
  const handleQuickFilterSubmit = (query, context) => {
    // If the context contains displayText, we update the input field
    // This way users see the friendly summary text in the input
    if (context.displayText) {
      setInputQuery(context.displayText);
    }
    
    onSubmit(query, false, '', context);
    setShowQuickFilter(false);
  };

  const handleRemoveTable = () => {
    setSelectedTable(null);
    setSelectedColumn(null);
  };

  const handleMentionSelect = ({ type, item, mentionStartIndex, formattedValue }) => {
    const textBeforeMention = inputQuery.slice(0, mentionStartIndex);
    const textAfterMention = inputQuery.slice(cursorPosition);  // Changed from inputValue to inputQuery
  
    const mentionText = type === 'value'
      ? `@${item.column.name}:${item.value}`
      : `@${formattedValue}`;
  
    const spacer = textAfterMention.startsWith(' ') ? '' : ' ';
    const updatedInput = `${textBeforeMention}${mentionText}${spacer}${textAfterMention}`;
    
    const newMention = {
      type,
      value: formattedValue,
      start: mentionStartIndex,
      end: mentionStartIndex + mentionText.length,
      item,
      isFromValueSearch: type === 'value' && item.isFromValueSearch
    };
  
    // Set usesTableMention to true if a table is mentioned
    if (type === 'table') {
      setUsesTableMention(true);
    }
  
    setSelectedTable(type === 'table' ? item : selectedTable);
    setSelectedColumn(type === 'column' ? item : selectedColumn);
    setInputQuery(updatedInput);
    setShowMentionList(false);
  
    const newPosition = mentionStartIndex + mentionText.length + spacer.length;
    requestAnimationFrame(() => {
      if (textareaRef.current) {
        textareaRef.current.focus(); 
        textareaRef.current.setSelectionRange(newPosition, newPosition);
      }
      setCursorPosition(newPosition);
    });
  };

  return (
    <div className="relative w-full">
      {showQuickFilter ? (
        <QuickFilter 
          onSubmit={handleQuickFilterSubmit}
          onClose={() => setShowQuickFilter(false)}
        />
      ) : (
        <>
          <ProposedQuestions
            onSelectQuestion={handleSelectProposedQuestion}
            visible={!queryResult && !isFollowUpMode && !inputQuery.trim() && !hideProposedQuestions}
          />
          <form onSubmit={handleSubmit} className="mb-4 relative w-full">
          <div className="relative w-full">
            <div className="relative w-full">
            {isFollowUpMode && (
              <div className="flex items-center gap-2 absolute left-2.5 top-3 z-10">
                {selectedColumns.length > 0 && (
                  <span 
                    ref={columnsChipRef}
                    className="text-xs px-2 py-1 rounded-full bg-accent text-white font-medium shadow-sm"
                  >
                    {selectedColumns[0]}:
                  </span>
                )}
                <span 
                  ref={chipRef}
                  className="text-xs px-2 py-1 rounded-full bg-gray-100 text-accent ring-1 ring-gray-200 font-medium shadow-sm"
                >
                  {selectedText ? truncateSelectedText(selectedText) : truncateSelectedText(isCardTitle)}
                </span>
              </div>
            )}
            <div className="w-full relative">
              <div 
                className={`bg-gray-700 rounded-full focus-within:ring-2 focus-within:ring-secondary ${
                  isFollowUpMode ? 'pl-4' : 'pl-6'
                } pr-12 sm:pr-16 overflow-hidden`}
              >
                <textarea
                  ref={textareaRef}
                  value={inputQuery}
                  onChange={handleTextareaChange}
                  onKeyDown={handleKeyDown}
                  onFocus={handleFocus}
                  onBlur={() => setIsFocused(false)}
                  placeholder={currentPlaceholder}
                  className="w-full bg-gray-700 border-none outline-none resize-none text-white rounded-full block leading-6"
                  style={{
                    paddingLeft: isFollowUpMode 
                      ? `${Math.max(
                          16 + 
                          (chipRef.current?.offsetWidth || 0) + 
                          (columnsChipRef.current?.offsetWidth || 0) + 
                          (selectedColumns.length ? 8 : 0), // Add small gap between chips if both exist
                          16
                        )}px` 
                      : '16px',
                    paddingTop: '12px',
                    paddingBottom: '12px',
                    paddingRight: '64px',
                    height: `${height}px`,
                    maxWidth: '100%',
                    minHeight: `${MIN_TEXTAREA_HEIGHT}px`,
                    overflow: height >= MIN_TEXTAREA_HEIGHT + (LINE_HEIGHT * (MAX_LINES - 1)) ? 'auto' : 'hidden'
                  }}
                  disabled={isLoading}
                />
                </div>
                {!isFollowUpMode && (
                  <img
                    src="/favicon.ico"
                    alt="Company Icon"
                    className="absolute left-2.5 top-1/2 -translate-y-1/2 w-6 h-6"
                  />
                )}
                <div className="absolute right-2 top-1/2 -translate-y-1/2 flex items-center space-x-1 sm:space-x-2">
                  {inputQuery && (
                    <button
                      type="button"
                      onClick={clearInput}
                      className="p-1 sm:p-1.5 hover:bg-gray-600 rounded-full transition-colors duration-200"
                      disabled={isLoading}
                    >
                      <X className="w-4 h-4 text-secondary" />
                    </button>
                  )}
                  {!isFollowUpMode &&(
                    <>
                      <button
                        type="button"
                        onClick={() => setShowQuickFilter(true)}
                        className="p-1.5 hover:bg-gray-600 rounded-full transition-colors duration-200"
                        disabled={isLoading}
                      >
                        <SlidersHorizontal className="w-4 h-4 text-secondary" />
                      </button>
                      <button
                        type="button"
                        onClick={handleHistoryBack}
                        className="p-1.5 hover:bg-gray-600 rounded-full transition-colors duration-200"
                        disabled={isLoading}
                      >
                        <ArrowLeft className="w-4 h-4 text-secondary" />
                      </button>
                    </>
                  )}
                  <button
                    type="submit"
                    className="p-1 sm:p-1.5 hover:bg-gray-600 rounded-full transition-colors duration-200"
                    disabled={isLoading || !inputQuery.trim()}
                  >
                    <SendHorizontal 
                      className={`w-4 h-4 ${
                        inputQuery.trim() ? 'text-secondary' : 'text-gray-400'
                      }`}
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
          {!isFollowUpMode && (
            <div ref={recentSearchesRef}>
              <RecentSearches
                onSelectQuery={handleSelectRecentQuery}
                isVisible={showRecentSearches}
                setIsVisible={setShowRecentSearches}
                recentSearches={filteredRecentSearches}
                highlightedIndex={highlightedIndex}
                onKeyDown={handleKeyDown}
              />
            </div>
          )}
          {isLoading && (
            <div className="h-10 mt-4">
              <div className="flex justify-center items-center">
                <div className="bubbling-loader">
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
                <span className="ml-2 text-[#4B5563] text-base">Processing query...</span>
              </div>
            </div>
          )}
          <MentionList
            isVisible={showMentionList}
            onSelect={handleMentionSelect}
            onClose={() => setShowMentionList(false)}
            inputValue={inputQuery}
            cursorPosition={cursorPosition}
            selectedTable={selectedTable}
            onRemoveTable={handleRemoveTable}
          />
          <AutoCompleteSearch
            onSelect={handleAutoComplete}
            inputValue={inputQuery}
            cursorPosition={cursorPosition}
            minChars={3}
            onVisibilityChange={setShowAutoComplete}
            onSuggestionsChange={setSuggestions}
            onSelectedIndexChange={setSelectedIndex}
            isLoading={isLoading}
            isFollowUpMode={isFollowUpMode} 
          />
        </form>
        </>
      )}
    </div>
  );
};

export default QueryInput;
import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate, Link } from 'react-router-dom';

function EmailVerification() {
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [isSending, setIsSending] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState(0);
  const { currentUser, sendVerificationEmail, logout } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    // Check if user exists and email is not verified
    if (!currentUser) {
      navigate('/login');
      return;
    }

    if (currentUser.emailVerified) {
      navigate('/');
      return;
    }

    // Check for email verification on focus
    const checkVerification = async () => {
      if (currentUser) {
        // Force token refresh to get the latest emailVerified status
        try {
          await currentUser.reload();
          if (currentUser.emailVerified) {
            navigate('/');
          }
        } catch (error) {
          console.error('Error reloading user:', error);
        }
      }
    };

    window.addEventListener('focus', checkVerification);
    
    // Initial check
    checkVerification();

    return () => {
      window.removeEventListener('focus', checkVerification);
    };
  }, [currentUser, navigate]);

  // Handle countdown for resend button
  useEffect(() => {
    if (timeRemaining > 0) {
      const intervalId = setInterval(() => {
        setTimeRemaining(prev => prev - 1);
      }, 1000);
      return () => clearInterval(intervalId);
    }
  }, [timeRemaining]);

  const handleResendVerification = async () => {
    if (isSending || timeRemaining > 0) return;
    
    setError('');
    setMessage('');
    setIsSending(true);
    
    try {
      await sendVerificationEmail();
      setMessage('Verification email sent! Please check your inbox.');
      setTimeRemaining(60); // Set 60 second cooldown
    } catch (error) {
      console.error('Error sending verification email:', error);
      setError('Failed to send verification email. Please try again.');
    } finally {
      setIsSending(false);
    }
  };

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/login');
    } catch (error) {
      console.error('Error logging out:', error);
      setError('Failed to log out. Please try again.');
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-background">
      <div className="w-full max-w-md px-4 py-8">
        <h1 className="text-title text-text mb-6 text-center">Verify Your Email</h1>
        <div className="bg-white shadow-card rounded-lg p-6">
          {error && (
            <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4" role="alert">
              <p>{error}</p>
            </div>
          )}
          {message && (
            <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded mb-4" role="alert">
              <p>{message}</p>
            </div>
          )}
          
          <div className="mb-6 text-center">
            <p className="mb-4">
              We've sent a verification email to <strong>{currentUser?.email}</strong>
            </p>
            <p className="mb-6">
              Please check your inbox and click the verification link to activate your account.
            </p>
            <p className="text-sm text-text-light mb-4">
              If you don't see the email, check your spam folder.
            </p>
          </div>
          
          <div className="flex flex-col space-y-4">
            <button
              onClick={handleResendVerification}
              disabled={isSending || timeRemaining > 0}
              className={`w-full bg-secondary text-text py-2 px-4 rounded-md hover:bg-opacity-80 focus:outline-none focus:ring-2 focus:ring-secondary focus:ring-opacity-50 transition duration-300 ${
                (isSending || timeRemaining > 0) ? 'opacity-50 cursor-not-allowed' : ''
              }`}
            >
              {isSending ? (
                <span className="flex items-center justify-center">
                  <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                  Sending...
                </span>
              ) : timeRemaining > 0 ? (
                `Resend Email (${timeRemaining}s)`
              ) : (
                'Resend Verification Email'
              )}
            </button>
            
            <button
              onClick={handleLogout}
              className="w-full border border-text-lighter text-text-light py-2 px-4 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-gray-200 transition duration-300"
            >
              Back to Login
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EmailVerification;
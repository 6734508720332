import React, { useState, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Database, AlertCircle, Check, Loader2, Bell, Download } from 'lucide-react';
import Button from '../Button';
import { format } from 'date-fns';
import { useConfig } from '../../contexts/ConfigContext';
import { useAuth } from '../../contexts/AuthContext';

// Create a "static" object outside the component to track downloads across all instances
// This persists even when components unmount
const DownloadTracker = {
  activePollers: {},
  
  startPolling: function(jobId, projectId, userEmail, isAdmin, documentId, fileName, onUpdate) {
    // Clear any existing poller for this document
    this.stopPolling(documentId);
    
    // Create new polling interval
    const intervalId = setInterval(async () => {
      try {
        // Check status endpoint
        const baseUrl = `https://us-central1-${projectId}.cloudfunctions.net/checkDownloadStatus`;
        const params = new URLSearchParams({
          jobId: jobId,
          userEmail: userEmail || 'anonymous',
          isAdmin: isAdmin || false
        });
        
        const response = await fetch(`${baseUrl}?${params.toString()}`);
        const data = await response.json();
        
        if (!response.ok) {
          throw new Error(data.error || 'Failed to check job status');
        }
        
        // Ensure progress is a number, default to 0 if not present or not valid
        const progress = typeof data.progress === 'number' ? data.progress : 0;
        
        // Update localStorage with latest status
        this.updateJobStatus(documentId, jobId, data.status, progress);
        
        // If component provided a callback, call it with normalized data
        if (onUpdate) {
          onUpdate({
            ...data,
            progress: progress
          });
        }
        
        // Handle completion
        if (data.status === 'completed') {
          // Trigger download using the signed URL(s)
          if (data.downloadUrl) {
            if (Array.isArray(data.downloadUrl)) {
              // Multiple files - download each file
              data.downloadUrl.forEach((fileInfo, index) => {
                // Small delay between downloads to prevent browser throttling
                setTimeout(() => {
                  const link = document.createElement('a');
                  link.href = fileInfo.url;
                  link.download = fileInfo.name || `${fileName}_part${index + 1}`;
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
                }, index * 500); // 500ms delay between downloads
              });
            } else {
              // Single file
              const link = document.createElement('a');
              link.href = data.downloadUrl;
              link.download = `${fileName || 'query_results'}`;
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            }
          }
          
          // Send notification
          this.sendNotification('Download Complete', `Your file ${fileName || 'query_results'} is ready.`);
          this.showToast('Download Complete!', `${fileName || 'Your file'} has been downloaded.`, 'success');
          
          // Stop polling
          this.stopPolling(documentId);
        } 
        // Handle failure
        else if (data.status === 'failed') {
          this.sendNotification('Download Failed', `There was an error downloading ${fileName || 'query_results'}.`);
          this.showToast('Download Failed', data.error || 'There was an error processing your download.', 'error');
          this.stopPolling(documentId);
        }
      } catch (error) {
        console.error('Error polling job status:', error);
        this.updateJobStatus(documentId, jobId, 'failed', null, error.message);
        if (onUpdate) {
          onUpdate({ status: 'failed', error: error.message });
        }
        this.stopPolling(documentId);
      }
    }, 3000);
    
    // Store the interval ID
    this.activePollers[documentId] = {
      intervalId,
      jobId,
      projectId,
      userEmail,
      isAdmin,
      fileName
    };
    
    // Initial localStorage entry
    this.updateJobStatus(documentId, jobId, 'processing', 0);
    
    return intervalId;
  },
  
  stopPolling: function(documentId) {
    if (this.activePollers[documentId]) {
      clearInterval(this.activePollers[documentId].intervalId);
      delete this.activePollers[documentId];
    }
  },
  
  updateJobStatus: function(documentId, jobId, status, progress = null, error = null) {
    try {
      // Get current active downloads
      const activeDownloads = JSON.parse(localStorage.getItem('activeDownloads') || '{}');
      
      if (status === 'completed' || status === 'failed') {
        // If job is done, remove it from active downloads after a delay
        // This allows components to see the completed status
        setTimeout(() => {
          try {
            const downloads = JSON.parse(localStorage.getItem('activeDownloads') || '{}');
            delete downloads[documentId];
            localStorage.setItem('activeDownloads', JSON.stringify(downloads));
          } catch (e) {
            console.error('Error cleaning up download:', e);
          }
        }, 60000); // Keep completed downloads in storage for 1 minute
      }
      
      // Update the status - ensure progress is a number
      activeDownloads[documentId] = {
        jobId,
        documentId,
        status,
        progress: typeof progress === 'number' ? progress : 0,
        error,
        updatedAt: new Date().toISOString()
      };
      
      // Save back to localStorage
      localStorage.setItem('activeDownloads', JSON.stringify(activeDownloads));
    } catch (error) {
      console.error('Error updating job status:', error);
    }
  },
  
  sendNotification: function(title, body) {
    // Just use toast notifications instead of browser notifications
    this.showToast(title, body, title.toLowerCase().includes('failed') ? 'error' : 'success');
  },
  
  // Show toast message
  showToast: function(title, message, type = 'info') {
    // Create toast container if it doesn't exist
    let toastContainer = document.getElementById('toast-container');
    if (!toastContainer) {
      toastContainer = document.createElement('div');
      toastContainer.id = 'toast-container';
      toastContainer.className = 'fixed bottom-4 right-4 z-50 flex flex-col gap-2';
      document.body.appendChild(toastContainer);
    }
    
    // Create toast element
    const toast = document.createElement('div');
    toast.className = `flex items-center p-3 rounded-md shadow-lg transition-all transform translate-x-0 mb-2 max-w-xs w-full text-sm ${
      type === 'success' ? 'bg-green-50 text-green-800 border border-green-200' : 
      type === 'error' ? 'bg-red-50 text-red-800 border border-red-200' : 
      'bg-blue-50 text-blue-800 border border-blue-200'
    }`;
    
    // Toast content
    toast.innerHTML = `
      <div class="flex-shrink-0 mr-2">
        ${type === 'success' ? 
          '<svg class="w-5 h-5 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg>' : 
          type === 'error' ? 
          '<svg class="w-5 h-5 text-red-500" fill="none" stroke="currentColor" viewBox="0 0 24 24"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg>' : 
          '<svg class="w-5 h-5 text-blue-500" fill="none" stroke="currentColor" viewBox="0 0 24 24"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>'
        }
      </div>
      <div class="flex-1">
        <p class="font-semibold">${title}</p>
        <p>${message}</p>
      </div>
      <button class="ml-2 text-gray-400 hover:text-gray-600 focus:outline-none" onclick="this.parentElement.remove()">
        <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg>
      </button>
    `;
    
    // Add to container
    toastContainer.appendChild(toast);
    
    // Auto remove after 5 seconds
    setTimeout(() => {
      if (toast.parentElement) {
        toast.classList.add('opacity-0');
        setTimeout(() => toast.remove(), 300);
      }
    }, 5000);
  },
  
  // Load any existing downloads from localStorage
  // This is called when the page loads to restore downloads
  restoreActiveDownloads: function() {
    try {
      const activeDownloads = JSON.parse(localStorage.getItem('activeDownloads') || '{}');
      
      // Loop through active downloads and restart polling for any in progress
      Object.entries(activeDownloads).forEach(([documentId, download]) => {
        if (download.status !== 'completed' && download.status !== 'failed') {
          // We don't have all information from localStorage, so just mark these as failed
          // if they were left hanging (they will be retried when user visits page)
          this.updateJobStatus(documentId, download.jobId, 'failed', null, 'Download was interrupted');
        }
      });
    } catch (error) {
      console.error('Error restoring active downloads:', error);
    }
  }
};

// Initialize the tracker on module load
DownloadTracker.restoreActiveDownloads();

const DownloadAllButton = ({ documentId, userId, fileName, disabled = false, icon: IconComponent = Download, title = "Download All Data", className = "", buttonText = null }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [downloadProgress, setDownloadProgress] = useState(null);
  const [downloadError, setDownloadError] = useState(null);
  const [downloadSuccess, setDownloadSuccess] = useState(false);
  const { config } = useConfig();
  const { currentUser, isAdmin } = useAuth();
  const buttonRef = useRef(null);
  const popupRef = useRef(null);
  const [popupPosition, setPopupPosition] = useState({ top: 0, left: 0 });
  
  // Position popup based on button position
  useEffect(() => {
    if (isOpen && buttonRef.current) {
      const buttonRect = buttonRef.current.getBoundingClientRect();
      const bodyRect = document.body.getBoundingClientRect();
      
      // Calculate available space below the button
      const spaceBelow = window.innerHeight - buttonRect.bottom;
      const popupHeight = 300; // Estimate the popup height
      
      // Position popup relative to the viewport
      let top = buttonRect.bottom + window.scrollY;
      
      // If not enough space below, position above
      if (spaceBelow < popupHeight && buttonRect.top > popupHeight) {
        top = buttonRect.top - popupHeight + window.scrollY;
      }
      
      // Calculate horizontal position
      let left = buttonRect.left + window.scrollX;
      
      // If popup would extend beyond right edge, align right edge with button right edge
      if (left + 256 > window.innerWidth) {
        left = buttonRect.right - 256 + window.scrollX;
      }
      
      setPopupPosition({ top, left });
    }
  }, [isOpen]);

  // Close popup when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isOpen && buttonRef.current && !buttonRef.current.contains(event.target) && 
          popupRef.current && !popupRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  // Check for existing downloads on mount and handle status updates
  useEffect(() => {
    if (!documentId) return;
    
    // Handler for status updates
    const handleUpdate = (data) => {
      console.log("Download status update:", data);
      
      if (data.status === 'processing' || data.status === 'queued') {
        setIsDownloading(true);
        // Ensure progress is always a number and at least 0
        setDownloadProgress(typeof data.progress === 'number' ? data.progress : 0);
      } else if (data.status === 'completed') {
        setIsDownloading(false);
        setDownloadSuccess(true);
        setDownloadProgress(100); // Set to 100% when complete
      } else if (data.status === 'failed') {
        setIsDownloading(false);
        setDownloadError(data.error || 'Download failed');
        setDownloadProgress(null);
      }
    };
    
    // Check if we have an active download for this document
    try {
      const activeDownloads = JSON.parse(localStorage.getItem('activeDownloads') || '{}');
      const existingJob = activeDownloads[documentId];
      
      if (existingJob) {
        console.log("Found existing job:", existingJob);
        
        // Set initial state based on stored job
        if (existingJob.status === 'processing' || existingJob.status === 'queued') {
          setIsDownloading(true);
          // Ensure progress is always a number and at least 0
          setDownloadProgress(typeof existingJob.progress === 'number' ? existingJob.progress : 0);
          
          // Restart polling (in case it stopped)
          DownloadTracker.startPolling(
            existingJob.jobId,
            config.core.projectId,
            currentUser?.email || userId || 'anonymous',
            isAdmin,
            documentId,
            fileName,
            handleUpdate
          );
        } else if (existingJob.status === 'completed') {
          setDownloadSuccess(true);
          setDownloadProgress(100); // Set to 100% when complete
        } else if (existingJob.status === 'failed') {
          setDownloadError(existingJob.error || 'Download failed');
          setDownloadProgress(null);
        }
      }
    } catch (error) {
      console.error('Error checking existing downloads:', error);
    }
    
    // Clean up on unmount
    return () => {
      // Note: We DON'T stop polling here, since we want it to continue in the background
      // We just clean up the React state/effects
    };
  }, [documentId, config.core.projectId, userId, currentUser, isAdmin, fileName]);

  // Clear success and error messages after 5 seconds
  useEffect(() => {
    if (downloadSuccess || downloadError) {
      const timer = setTimeout(() => {
        setDownloadSuccess(false);
        setDownloadError(null);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [downloadSuccess, downloadError]);

  const handleStartDownload = async (format = 'csv', usePagination = false) => {
    if (!documentId) {
      console.error('Missing documentId:', documentId);
      setDownloadError('Error: Document ID is missing');
      return;
    }
    
    setIsDownloading(true);
    setDownloadError(null);
    setDownloadSuccess(false);
    setDownloadProgress(0);
    setIsOpen(false);
    
    try {
      // Determine which endpoint to use based on pagination parameter
      const baseUrl = `https://us-central1-${config.core.projectId}.cloudfunctions.net/${
        usePagination ? 'directDownloadWithPagination' : 'directDownloadWithClientLibrary'
      }`;
      
      const params = new URLSearchParams({
        documentId: documentId,
        userId: currentUser?.email || userId || 'anonymous',
        userEmail: currentUser?.email || userId || 'anonymous',
        isAdmin: isAdmin || false,
        format: format,
        fileName: fileName || `query_results_${format(new Date(), 'yyyyMMdd_HHmmss')}`,
        useExportData: 'false', // Don't fall back to EXPORT DATA unless needed
        addBom: format.toLowerCase() === 'csv' ? 'true' : 'false' // Add BOM for CSV files to handle Hebrew in Excel
      });
      
      // Add pagination parameters if using pagination
      if (usePagination) {
        params.append('pageSize', '10000'); // Default page size
      }
      
      const response = await fetch(`${baseUrl}?${params.toString()}`);
      const data = await response.json();
      
      if (!response.ok) {
        throw new Error(data.error || 'Failed to start download');
      }
      
      if (data.jobId) {
        // Show a toast notification
        DownloadTracker.showToast(
          'Download Started', 
          `${fileName || 'Your file'} is being prepared and will download automatically when ready. Feel free to continue working.`,
          'info'
        );
        
        // Handler for status updates
        const handleUpdate = (statusData) => {
          console.log("Status update from job:", statusData);
          
          if (statusData.status === 'processing' || statusData.status === 'queued') {
            setIsDownloading(true);
            // Ensure progress is always a number and at least 0
            setDownloadProgress(typeof statusData.progress === 'number' ? statusData.progress : 0);
          } else if (statusData.status === 'completed') {
            setIsDownloading(false);
            setDownloadSuccess(true);
            setDownloadProgress(100); // Set to 100% when complete
          } else if (statusData.status === 'failed') {
            setIsDownloading(false);
            setDownloadError(statusData.error || 'Download failed');
            setDownloadProgress(null);
          }
        };
        
        // Start tracking with DownloadTracker
        DownloadTracker.startPolling(
          data.jobId,
          config.core.projectId,
          currentUser?.email || userId || 'anonymous',
          isAdmin,
          documentId,
          fileName,
          handleUpdate
        );
        
      } else if (data.downloadUrl) {
        // Direct download is available
        setIsDownloading(false);
        setDownloadSuccess(true);
        setDownloadProgress(100);  // Set to 100% for direct downloads
        
        // Trigger download using the signed URL without new tab
        const link = document.createElement('a');
        link.href = data.downloadUrl;
        link.download = fileName || 'query_result.csv';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        throw new Error('No download information returned from server');
      }
      
    } catch (error) {
      console.error('Download error:', error);
      setDownloadError(error.message || 'Unknown error');
      setIsDownloading(false);
    }
  };

  return (
    <div className="relative" ref={buttonRef}>
      <Button
        onClick={() => setIsOpen(!isOpen)}
        icon={isDownloading ? Loader2 : IconComponent}
        className={`${className} 
          ${disabled ? 'opacity-50 cursor-not-allowed' : 'text-primary border-primary'} 
          ${downloadError && !isDownloading ? 'text-red-500 border-red-500' : ''} 
          ${downloadSuccess ? 'text-green-500 border-green-500' : ''} 
          ${isDownloading ? 'text-blue-500 border-blue-500' : ''}`}
        title={downloadError || (downloadSuccess ? 'Download successful!' : title)}
        disabled={disabled || !documentId}
      >
        {buttonText && <span className="ml-1">{buttonText}</span>}
        {downloadError && !isDownloading && (
          <AlertCircle size={16} className="text-red-500 ml-1" />
        )}
        {downloadSuccess && (
          <Check size={16} className="text-green-500 ml-1" />
        )}
        {isDownloading && downloadProgress !== null && (
          <span className="relative ml-1 flex h-4 w-4 items-center justify-center">
            <span className="animate-ping absolute h-full w-full rounded-full bg-blue-300 opacity-75"></span>
            <span className="relative h-2 w-2 rounded-full bg-blue-500"></span>
          </span>
        )}
        {isDownloading && downloadProgress === null && (
          <Loader2 size={16} className="animate-spin text-blue-500 ml-1" />
        )}
      </Button>
      
      {isOpen && ReactDOM.createPortal(
        <div 
          ref={popupRef}
          className="fixed bg-white shadow-lg rounded-md p-2 border border-gray-200"
          style={{ 
            top: `${popupPosition.top}px`, 
            left: `${popupPosition.left}px`, 
            width: '256px',
            zIndex: 99999, // Very high z-index to ensure it's on top
            maxHeight: '80vh',
            overflowY: 'auto'
          }}
        >
          <div className="text-sm font-medium text-gray-700 mb-1 px-2">Download All Data</div>
          
          {downloadError && (
            <div className="px-2 py-1 mb-2 text-xs text-red-600 bg-red-50 rounded border border-red-100">
              {downloadError}
            </div>
          )}
          
          {downloadSuccess && (
            <div className="px-2 py-1 mb-2 text-xs text-green-600 bg-green-50 rounded border border-green-100">
              Download successful! Check your downloads folder.
            </div>
          )}
          
          {isDownloading && downloadProgress !== null && (
            <div className="px-2 py-1 mb-2">
              <div className="text-xs text-gray-600 mb-1">Download progress:</div>
              <div className="w-full bg-gray-200 rounded-full h-2">
                <div 
                  className="bg-blue-500 h-2 rounded-full" 
                  style={{ width: `${Math.min(100, downloadProgress)}%` }}
                ></div>
              </div>
              <div className="text-xs text-gray-500 text-right mt-1">
                Preparing download...
              </div>
            </div>
          )}
          
          <div className="px-3 py-2 mb-2 text-xs text-blue-700 bg-blue-50 rounded border border-blue-100 flex items-start">
            <Bell size={14} className="text-blue-500 mr-2 flex-shrink-0 mt-0.5" />
            <div>
              <span className="font-semibold">Background Download:</span> Your file will be prepared in the background and will download automatically when ready. You can continue working normally.
            </div>
          </div>
          
          <button 
            onClick={() => handleStartDownload('csv')} 
            className={`w-full text-left p-2 hover:bg-gray-100 rounded-md text-sm ${isDownloading ? 'opacity-50 cursor-not-allowed' : ''}`}
            disabled={!documentId || isDownloading}
          >
            <span className="flex-grow">CSV</span>
            <span className="text-xs text-gray-500"> (Best for large data)</span>
          </button>
          <button 
            onClick={() => handleStartDownload('json')} 
            className={`w-full text-left p-2 hover:bg-gray-100 rounded-md text-sm ${isDownloading ? 'opacity-50 cursor-not-allowed' : ''}`}
            disabled={!documentId || isDownloading}
          >
            <span className="flex-grow">JSON</span>
          </button>
        </div>,
        document.body
      )}
    </div>
  );
};

export default DownloadAllButton;